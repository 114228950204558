/**
 * Утилиты Post
 */
const UtilsPost = {
  // отправка формы
  correctAndFilterPosts(posts, filter) {
    const result = [];
    posts.forEach(post => {
      const { frontmatter, fields } = post.node;
      fields.slug = this._correctSlug(frontmatter.url);
      frontmatter.description = this._correctDescr(frontmatter.description);
      frontmatter.category.forEach(cat => {
        if (filter.indexOf(cat) !== -1) {
          result.push(post);
        }
      });

      frontmatter.subcategory.forEach(cat => {
        if (filter.indexOf(cat) !== -1) {
          result.push(post);
        }
      });
    });
    return result;
  },

  _correctSlug(url) {
    // const slugArray = post.node.fields.slug.split('/');
    // if (slugArray.length > 1) {
    //   post.node.fields.slug = slugArray[slugArray.length - 2];
    // }
    // post.node.fields.slug = post.node.frontmatter.url;
    return url;
  },

  _correctDescr(description) {
    const lengthString = 270;
    let newDes = '';

    if (description.length) {
      const desArray = description.split('.');
      desArray.forEach(d => {
        if (d && newDes.length + d.length < lengthString) {
          newDes = `${newDes} ${d}.`;
        }
      });
      // const subText = description.substr(0, lengthString);
    }
    return newDes;
  }
};

export default UtilsPost;
