import React from 'react';
import PropTypes from 'prop-types';
import {graphql, StaticQuery} from 'gatsby';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import CasePage from '../templates/Case';
// import corezoidBlue from '../images/CorezoidBlue.webp';
import UtilsPost from '../utils/post';

// при изменении кейсов так же заменить на странице resource
const cases = {
  title: 'resourceCaseTitle',
  subTitle: 'resourceCaseSubTitle',
  ref: 'case',
  image: 'bag.png',
  button: {
    text: 'resourceCaseButtonText',
    link: '/case-studies'
  },
  showMore: 'resourceCaseShowMore',
  list: [
    // {
    //   image: corezoidBlue,
    //   date: '2018-08-07',
    //   title: 'resourceCaseItem1Title',
    //   link: '',
    //   more: 'resourceCaseItem1More'
    // }
  ],
};

const CaseStudies = (props) => (
  <Layout {...props}>
    <StaticQuery
      query={graphql`
      query IndexQueryCaseStudies {
        allMarkdownRemark(
          sort: {fields: [frontmatter___date], order: DESC },
          filter: { fields: { slug: { regex: "/.*blog.*/" }}}
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date
                title
                url
                description
                image
                lang
                category
                subcategory
              }
            }
          }
        }
      }
    `}
      render={data => {
        const category = ['Use cases'];
        const allPosts = data.allMarkdownRemark.edges;
        const posts = UtilsPost.correctAndFilterPosts(allPosts, category);
        const lang = props.pageContext.locale ? props.pageContext.locale : 'en';

        const list = [];

        if (list.length === 0) {
          posts.forEach(post => {
            if (post.node.frontmatter.lang === lang) {
              list.push(
                {
                  image: post.node.frontmatter.image,
                  date: post.node.frontmatter.date,
                  title: post.node.frontmatter.title,
                  link: `/blog/${post.node.frontmatter.url}`,
                  more: 'resourceCaseItem1More',
                }
              );
            }
          });
        }
        cases.list = list;

        return (
          <CasePage
            {...props}
            data={cases}
          />
        );
      }}
    />
  </Layout>
);

CaseStudies.propTypes = {
  pageContext: PropTypes.object
};

export default withIntl(CaseStudies);
